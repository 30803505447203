import TonButton from "../../components/TonButton/TonButton";
import { TopControl } from "../../components/TopControl";
import { BigEggSvg } from "./BigEggSvg";

export const Airdrop = () => {
  return (
    <>
      <TopControl name="airdrop" />
      <div className="flex flex-col items-center justify-center">
        <img src="./img/coins.png" className="w-56 h-56" />
        <div className="text-center my-4">
          Complete tasks and
          <br /> participate in the drop
        </div>
        <TonButton />
      </div>
    </>
  );
};

import { useMemo, useEffect, useContext, useState } from "react";
import { useUser } from "../hooks/useUser";
import { useWebSocket } from "../hooks/useWebsocket";
import { NotifyContext } from "../contexts/NotifyContext";

export const DailyOffer = () => {
  const { user } = useUser();

  const dateUnlock = useMemo(() => {
    if (!user) {
      return null;
    }
    const hoursToUnlock =
      24 - (Date.now() - user.lastDailyRewardTimestamp) / 1000 / 60 / 60;

    const roundedHour = hoursToUnlock.toString().split(".")[0];
    const roundedMinute = ((hoursToUnlock - +roundedHour) * 60)
      .toString()
      .split(".")[0];
    return {
      hours: roundedHour,
      minutes: roundedMinute,
    };
  }, [user]);

  const dailyDisabled = useMemo(() => {
    if (user) {
      return Date.now() - user.lastDailyRewardTimestamp < 1000 * 60 * 60 * 24;
    } else {
      return true;
    }
  }, [user]);


  return (
    <div
      className={
        "p-2 pt-4 rounded-lg flex flex-col justify-center items-center relative"
        // (dailyDisabled ? "relative opacity-50" : "relative")
      }
      style={{
        background:
          "linear-gradient(180deg, rgba(50, 57, 71, 0.8) 0%, rgba(67, 84, 122, 0.8) 100%)",
      }}
    >
      <img className="w-8" src="./img/calendar.png" />
      <div className="uppercase text-xs mt-2">Daily Reward</div>
      {dailyDisabled && (
        <div className="flex text-xs gap-2 p-1 bg-[#12131733] rounded-full">
          <div className="bg-[#273148] rounded-full p-1 w-6 h-6">
            {dateUnlock?.hours}
          </div>
          <div>:</div>
          <div className="bg-[#273148] rounded-full p-1 w-6 h-6">
            {dateUnlock?.minutes}
          </div>
        </div>
      )}
    </div>
  );
};

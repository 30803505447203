import { useRef, useEffect } from "react";
import { NotifyMessage } from "../contexts/NotifyContext";
import { SuccessSvg } from "./SuccessSvg";

export const Notification = ({
  notify,
  onClose,
}: {
  notify: NotifyMessage;
  onClose: any;
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const timeoutsRef = useRef<NodeJS.Timeout[]>([]); // Храним идентификаторы таймеров

  useEffect(() => {
    const TIMEOUT = 3000;

    modalRef.current?.classList.add("visible");

    const hideTimeout = setTimeout(() => {
      modalRef.current?.classList.remove("visible");
      modalRef.current?.classList.add("hidden");
    }, TIMEOUT - 300);

    const closeTimeout = setTimeout(() => {
      onClose();
    }, TIMEOUT);

    timeoutsRef.current.push(hideTimeout, closeTimeout);

    return () => {
      timeoutsRef.current.forEach(clearTimeout);
      timeoutsRef.current = [];
      modalRef.current?.classList.remove("visible");
    };
  }, []);

  const overlayClickHandle = () => {
    timeoutsRef.current.forEach(clearTimeout);
    timeoutsRef.current = [];
    modalRef.current?.classList.remove("visible");
    modalRef.current?.classList.add("hidden");
    onClose();
  };

  return (
    <>
      <div className="overlay-safe" onClick={overlayClickHandle} />
      <div
        id="modal"
        className={`modal ${notify.className}`}
        ref={modalRef}
        style={{ zIndex: 20 }}
      >
        <div
          className={
            "w-full h-full flex flex-col justify-center items-center rounded-b-xl " +
            (notify.status == "task" ? "text-[#35CE28]" : "text-red")
          }
        >
          <div className="text-lg text-center">{notify.message}</div>
          <div className="mt-8">
            {notify.status == "task" ? <SuccessSvg /> : null}
          </div>
          {notify.closeButton && (
            <button
              className="text-white px-6 py-2 relative top-8 rounded-lg"
              style={{
                background: "linear-gradient(180deg, #F4895D 0%, #FF4C64 100%)",
              }}
            >
              Сompleted
            </button>
          )}
        </div>
      </div>
    </>
  );
};

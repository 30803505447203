import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { useWebSocket } from "../../hooks/useWebsocket";
import { useUser } from "../../hooks/useUser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BoostButton } from "../../components/BoostButton";
import { RestoreSvg } from "./RestoreSvg";
import { MassTapSvg } from "./MassTapSvg";
import { BoostModal } from "./BoostModal";
import { useSettings } from "../../hooks/useSettings";
import { NotifyContext, NotifyMessage } from "../../contexts/NotifyContext";
import { DataContext } from "../../contexts/DataContext";
import { TopControl } from "../../components/TopControl";
import { formatNumber } from "../../utils/formatNumber";
import { useNavigate } from "react-router-dom";

type Boost = {
  id: number;
  img: string;
  title: string;
  description: string;
};

const boosts: Boost[] = [
  {
    id: 0,
    img: "./img/energy_ext.png",
    title: "FULL ENERGY",
    description:
      "Recharge your energy to the maximum and do another round of mining!",
  },
  {
    id: 1,
    img: "./img/multitap_ext.png",
    title: "Multitap",
    description: "Increase the amount of BILLIES you can earn per swipe",
  },
  {
    id: 2,
    img: "./img/energylimit.png",
    title: "Energy limit",
    description: "Increases the amount of energy available to the user",
  },
];

export const Boosts = () => {
  const { webSocket } = useWebSocket();
  const { user } = useUser();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedBoost, setSelectedBoost] = useState<Boost | null>(null);
  const { startClickUpgradeCost, startEnergyUpgradeCost } = useSettings();
  const notifyContext = useContext(NotifyContext);
  const { maxClickLevel, maxEnergyLevel, fullEnergyBoostPerDay } =
    useSettings();
  const navigate = useNavigate();

  const [isEnergyUpgrading, setEnergyUpgrading] = useState(false);
  const [isEnergyRestoring, setEnergyRestroring] = useState(false);
  const [isClickUpgrading, setClickUpgrading] = useState(false);

  useEffect(() => {
    if (webSocket) {
      const handleBoostActivated = ({ success, message }: any) => {
        const notify: NotifyMessage = {
          status: "ok",
          message: message,
        };
        setEnergyRestroring(false);
        notifyContext?.setNotify(notify);
      };

      webSocket.on("boostActivated", handleBoostActivated);

      webSocket.on("clickPowerUpgraded", ({ success }) => {
        setClickUpgrading(false);
        console.log("clickPowerUpgraded", success);

        const notify: NotifyMessage = {
          status: success ? "ok" : "error",
          message: success
            ? "The power of your click has been enhanced"
            : "The power of your click was not increased",
        };
        notifyContext?.setNotify(notify);
      });

      webSocket.on("energyUpgraded", ({ success }) => {
        setEnergyUpgrading(false);
        const notify: NotifyMessage = {
          status: success ? "ok" : "error",
          message: success
            ? "Your energy level has been enhanced"
            : "Your energy level was not enhanced",
        };
        notifyContext?.setNotify(notify);
      });
      return () => {
        webSocket.off("boostActivated", handleBoostActivated);
        webSocket.off("clickPowerUpgraded");
      };
    }
  }, [webSocket]);

  useEffect(() => {
    if (typeof window.Telegram.WebApp !== "undefined") {
      console.log("show");

      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(function () {
        window.history.back();
      });
    }
    return () => {
      if (typeof window.Telegram.WebApp !== "undefined") {
        window.Telegram.WebApp.BackButton.hide();
      }
    };
  }, []);

  const activateFullEnergyBoost = () => {
    if (webSocket) {
      if (fullEnergyActivates < 3) {
        setEnergyRestroring(true);
        webSocket.emit(
          "activateBoost",
          JSON.stringify([user?.tgId, "fullEnergyBoost"])
        );
        // const notify: NotifyMessage = {
        //   status: "ok",
        //   className: "h-72",
        //   message: "The energy has been restored",
        // };
        // notifyContext?.setNotify(notify);
      } else {
        // const notify: NotifyMessage = {
        //   status: "error",
        //   message: "You can't restore energy today",
        // };
        // notifyContext?.setNotify(notify);
      }
    }
  };

  const improveClick = () => {
    if (!!user && user?.clickPower >= maxClickLevel) {
      const notify: NotifyMessage = {
        status: "unknown",
        message: "You have reached the maximum clicker level",
      };
      notifyContext?.setNotify(notify);
      return;
    }
    if (
      webSocket &&
      startClickUpgradeCost * 2 ** ((user?.clickPower || 2) - 1) <=
        (user?.balance || 0)
    ) {
      setClickUpgrading(true);
      webSocket.emit("upgradeClick", user?.tgId);
    } else {
      const notify: NotifyMessage = {
        status: "error",
        message: "You don't have enough balance",
      };
      notifyContext?.setNotify(notify);
    }
  };

  const upgradeEnergy = () => {
    if (!!user?.energyLevel && user.energyLevel >= maxEnergyLevel) {
      const notify: NotifyMessage = {
        status: "unknown",
        message: "You have reached the maximum energy level",
      };
      notifyContext?.setNotify(notify);
      return;
    }

    if (
      webSocket &&
      startEnergyUpgradeCost * 2 ** ((user?.energyLevel || 2) - 1) <=
        (user?.balance || 0)
    ) {
      webSocket.emit("upgradeEnergy", user?.tgId);
      setEnergyUpgrading(true);
    } else {
      const notify: NotifyMessage = {
        status: "error",
        message: "You don't have enough balance",
      };
      notifyContext?.setNotify(notify);
    }
  };

  const energyDisabled = useMemo(() => {
    if (user) {
      return (
        Date.now() - user?.lastFullEnergyTimestamp < 1000 * 60 * 60 * 24 &&
        user?.fullEnergyActivates >= 3
      );
    } else {
      return true;
    }
  }, [user]);

  const fullEnergyActivates = useMemo(() => {
    if (user) {
      if (Date.now() - user?.lastFullEnergyTimestamp > 1000 * 60 * 60 * 24) {
        return 0;
      }
    }
    return user?.fullEnergyActivates || 0;
  }, [user]);

  const onClose = () => {
    setModalOpen(false);
  };
  const onPurchase = () => {
    // onClose();

    switch (selectedBoost?.id) {
      case 0:
        activateFullEnergyBoost();
        break;
      case 1:
        improveClick();
        break;
      case 2:
        upgradeEnergy();
        break;
    }
  };

  const price = useMemo(() => {
    if (selectedBoost?.id == 0) {
      return "Free";
    }
    if (selectedBoost?.id == 1) {
      return formatNumber(
        startClickUpgradeCost * 2 ** ((user?.clickPower || 2) - 1)
      );
    }
    if (selectedBoost?.id == 2) {
      return formatNumber(
        startEnergyUpgradeCost * 2 ** ((user?.energyLevel || 2) - 1)
      );
    }
  }, [selectedBoost]);

  return (
    <>
      <TopControl name="boosts" />
      <div className="font-sans p-5 pt-0 rounded-lg max-w-md mx-auto">
        <div className="mt-4 flex flex-col gap-4">
          <div className="uppercase text-center">Free Daily Boosters:</div>
          <div
            className={
              "relative flex justify-start items-center bg-[#333A48B2] rounded-2xl px-6 py-2 gap-6 " +
              (isEnergyRestoring ? "opacity-50" : "opacity-100")
            }
            onClick={() => {
              setModalOpen(true);
              setSelectedBoost(boosts[0]);
            }}
          >
            <img src="./img/energy.png" className="w-6" />
            <div className="flex flex-col justify-center">
              <div className="uppercase font-light">Full energy</div>
              <div className="text-[#677496]">
                {3 - fullEnergyActivates}/{fullEnergyBoostPerDay} available
              </div>
            </div>
          </div>

          <div className="mt-4 text-center uppercase">Boosters</div>
          <div
            className={
              "relative flex justify-start items-center bg-[#333A48B2] rounded-2xl px-6 py-2 gap-6 " +
              (isClickUpgrading ? "opacity-50" : "opacity-100")
            }
            onClick={() => {
              setModalOpen(true);
              setSelectedBoost(boosts[1]);
            }}
          >
            <img src="./img/multitap.png" className="w-6" />
            <div className="flex flex-col justify-center">
              <div className="uppercase font-light">Multitap </div>
              <div className="flex items-center gap-2">
                <img src="./img/coin.png" className="w-5 h-5" />
                <div className="text-[#E0AE10]">
                  {formatNumber(
                    startClickUpgradeCost * 2 ** ((user?.clickPower || 2) - 1)
                  )}
                </div>
                <div className="w-2 h-2 rounded-full bg-[#677496]" />
                <div className="text-[#677496] font-bold">
                  {user?.clickPower} lvl
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              "relative flex justify-start items-center bg-[#333A48B2] rounded-2xl px-6 py-2 gap-6 " +
              (isEnergyUpgrading ? "opacity-50" : "opacity-100")
            }
            onClick={() => {
              setModalOpen(true);
              setSelectedBoost(boosts[2]);
            }}
          >
            <img src="./img/energylimit.png" className="w-6" />
            <div className="flex flex-col justify-center">
              <div className="uppercase font-light">Energy limit</div>
              <div className="flex items-center gap-2">
                <img src="./img/coin.png" className="w-5 h-5" />
                <div className="text-[#E0AE10]">
                  {formatNumber(
                    startEnergyUpgradeCost * 2 ** ((user?.energyLevel || 2) - 1)
                  )}
                </div>
                <div className="w-2 h-2 rounded-full bg-[#677496]" />
                <div className="text-[#677496] font-bold">
                  {user?.energyLevel} lvl
                </div>
              </div>
            </div>
          </div>
        </div>

        {isModalOpen && selectedBoost && (
          <BoostModal
            img={selectedBoost.img}
            title={selectedBoost.title}
            onClose={onClose}
            onPurchase={onPurchase}
            description={selectedBoost.description}
            price={price}
          />
        )}
      </div>
    </>
  );
};

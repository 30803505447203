import React, { useState, useEffect, useRef, memo } from "react";
import { useClick } from "../../hooks/useClick";
import NumberSign from "../../components/NumberSign";
import { EnergyProgress } from "../../components/EnergyProgress";
import { ScoreCounter } from "../../components/ScoreCounter";
import TonButton from "../../components/TonButton/TonButton";
import { useUser } from "../../hooks/useUser";
import { usePageLoading } from "../../hooks/usePageLoading";
import { useSkeletonLoading } from "../../hooks/useSkeletonLoading";
import { ClickerSkeleton } from "./ClickerSkeleton";
import { League } from "../../components/League";
import { BoostButton } from "../../components/BoostButton";
import "./Clicker.css"; // Создайте и импортируйте CSS файл
import { Statistics } from "../../components/Statistics";
import { useSettings } from "../../hooks/useSettings";
import { LevelStatus } from "../../components/LevelStatus";
import { DailyOffer } from "../../components/DailyOffer";
import { ComboGame } from "../../components/ComboGame";
import { formatNumber } from "../../utils/formatNumber";

export const Clicker: React.FC = () => {
  const { handleClick, clickCount, energyCount } = useClick();
  const imgRef = useRef<HTMLImageElement>(null);

  const [numberSignPositions, setNumberSignPositions] = useState<
    { x: number; y: number; id: number }[]
  >([]);
  const [numberSignId, setNumberSignId] = useState<number>(0);
  const [activeTouches, setActiveTouches] = useState<Set<number>>(new Set());
  const [imageClicked, setImageClicked] = useState<boolean>(false);
  const { user } = useUser();
  const isSkeletonLoading = useSkeletonLoading();
  const [devData, setDevData] = useState<any>(null);

  const handleTouchStart = (event: TouchEvent) => {
    const newTouches = new Set<number>(
      Array.from(event.touches, (touch) => touch.identifier)
    );
    const newTouchIdentifiers = Array.from(newTouches).filter(
      (identifier) => !activeTouches.has(identifier)
    );

    if (newTouchIdentifiers.length > 0) {
      const touchPositions = Array.from(event.touches)
        .filter((touch) => newTouchIdentifiers.includes(touch.identifier))
        .map((touch, index) => ({
          x: touch.clientX,
          y: touch.clientY,
          id: numberSignId + index + 1,
          timestamp: Date.now(),
        }));

      if (energyCount > 0) {
        setNumberSignId((prevId) => prevId + touchPositions.length);

        setImageClicked(true);
        setTimeout(() => setImageClicked(false), 50);

        setNumberSignPositions((prevPositions) => [
          ...prevPositions,
          ...touchPositions,
        ]);
      }

      touchPositions.forEach((position) => {
        if (user) {
          handleClick({
            user_id: user.tgId,
            position: { x: position.x, y: position.y },
            time_stamp: Date.now(),
          });
        }
      });

      setActiveTouches(newTouches);
    }
  };

  const handleTouchMove = (event: TouchEvent) => {};

  const handleTouchEnd = (event: TouchEvent) => {
    event.preventDefault();
    const remainingTouches = new Set<number>(
      Array.from(event.touches, (touch) => touch.identifier)
    );
    setActiveTouches(remainingTouches);
  };

  const handleAnimationEnd = (id: number) => {
    setNumberSignPositions((prevPositions) =>
      prevPositions.filter((position) => position.id !== id)
    );
  };

  const handleContextMenu = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    event.preventDefault();
  };
  return (
    <div
      className="text-center h-full relative flex flex-col items-center"
      onScroll={(e) => e.preventDefault()}
    >
      {isSkeletonLoading ? (
        <ClickerSkeleton />
      ) : (
        <>
          <div className="w-full flex justify-between p-2">
            <svg
              onClick={() => window.Telegram.WebApp.close()}
              width="35"
              height="35"
              viewBox="0 0 81 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="10"
                y="10"
                width="61"
                height="61"
                rx="30.5"
                fill="#273148"
              />
              <rect
                x="5"
                y="5"
                width="71"
                height="71"
                rx="35.5"
                stroke="#273148"
                stroke-opacity="0.3"
                stroke-width="10"
              />
              <rect
                x="48.2697"
                y="30.0001"
                width="4"
                height="26"
                rx="2"
                transform="rotate(45 48.2697 30.0001)"
                fill="#C0C9DD"
              />
              <rect
                x="51.2693"
                y="48.0002"
                width="4"
                height="26"
                rx="2"
                transform="rotate(135 51.2693 48.0002)"
                fill="#C0C9DD"
              />
            </svg>
            <div className="font-extrabold uppercase text-xl">Billy Rush</div>
            <svg
              width="35"
              height="35"
              viewBox="0 0 81 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="10"
                y="10"
                width="61"
                height="61"
                rx="30.5"
                fill="#273148"
              />
              <rect
                x="5"
                y="5"
                width="71"
                height="71"
                rx="35.5"
                stroke="#273148"
                stroke-opacity="0.3"
                stroke-width="10"
              />
              <circle cx="24.5" cy="40.5" r="4" fill="#C0C9DD" />
              <circle cx="40.5" cy="40.5" r="4" fill="#C0C9DD" />
              <circle cx="56.5" cy="40.5" r="4" fill="#C0C9DD" />
            </svg>
          </div>
          <div className="px-10 pb-4 w-full">
            <div className="flex justify-start items-center w-full mt-1 mb-1 gap-2 bg-[#333A4866] rounded-full">
              <div className="p-2">
                <div className="dounded-full w-10 h-10 flex items-center justify-center">
                  {user?.firstName[0]}
                </div>
              </div>
              <div className="w-1/2">
                <div>
                  <div className="flex justify-between">
                    <div>{user?.firstName}</div>
                    <div className="text-[#515A70] text-sm">
                      {user?.userLevel}/{user?.maxLevel}
                    </div>
                  </div>
                  <League />
                </div>
              </div>
              <div className="border border-solid h-14 border-[#4E556780]" />
              <div className="px-2 ">
                <div className="text-xs text-[#515A70] wrap">
                  Profit per hour
                </div>
                <div className="flex items-center justify-between pr-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 31 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5 30C23.7843 30 30.5 23.2843 30.5 15C30.5 6.71573 23.7843 0 15.5 0C7.21573 0 0.5 6.71573 0.5 15C0.5 23.2843 7.21573 30 15.5 30Z"
                      fill="#E0AE10"
                    />
                    <path
                      d="M15.3054 27.6624C22.1911 27.6624 27.773 22.0805 27.773 15.1948C27.773 8.3092 22.1911 2.72729 15.3054 2.72729C8.4198 2.72729 2.83789 8.3092 2.83789 15.1948C2.83789 22.0805 8.4198 27.6624 15.3054 27.6624Z"
                      fill="#795D04"
                    />
                    <path
                      d="M9.71289 22.013V7.84534H15.8559C16.9351 7.84534 17.8413 7.99061 18.5746 8.28116C19.3125 8.57171 19.8682 8.98216 20.2418 9.51253C20.62 10.0429 20.809 10.6678 20.809 11.3872C20.809 11.9084 20.6937 12.3834 20.4632 12.8123C20.2372 13.2412 19.919 13.6009 19.5085 13.8915C19.098 14.1774 18.6184 14.3757 18.0696 14.4864V14.6248C18.6784 14.6478 19.2318 14.8023 19.7299 15.0883C20.2279 15.3696 20.6246 15.7593 20.9197 16.2574C21.2149 16.7508 21.3625 17.3319 21.3625 18.0007C21.3625 18.7755 21.1595 19.4649 20.7537 20.0691C20.3525 20.6732 19.7806 21.1483 19.0381 21.4942C18.2956 21.84 17.4101 22.013 16.3816 22.013H9.71289ZM13.5592 18.9415H15.3578C16.0035 18.9415 16.4877 18.8216 16.8106 18.5818C17.1334 18.3373 17.2948 17.9776 17.2948 17.5026C17.2948 17.1705 17.2187 16.8892 17.0665 16.6586C16.9143 16.428 16.6976 16.2528 16.4162 16.1329C16.1395 16.0129 15.8052 15.953 15.4132 15.953H13.5592V18.9415ZM13.5592 13.5733H15.1364C15.4731 13.5733 15.7706 13.5202 16.0288 13.4142C16.2871 13.3081 16.4877 13.1559 16.6307 12.9576C16.7783 12.7547 16.8521 12.5079 16.8521 12.2174C16.8521 11.7793 16.6953 11.4449 16.3816 11.2143C16.068 10.9791 15.6714 10.8615 15.1918 10.8615H13.5592V13.5733Z"
                      fill="white"
                    />
                  </svg>
                  <div className="text-sm">
                    {formatNumber(user?.totalIncomePerHour || 0)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="rounded-xl h-full p-6 flex flex-col items-center gap-2 w-full"
            style={{
              background:
                "linear-gradient(180deg, rgba(35, 39, 48, 0.8) 0%, rgba(29, 32, 39, 0.8) 100%)",
            }}
          >
            <div className="flex justify-center">
              <DailyOffer />
            </div>
            <div className="flex justify-center gap-4">
              <img src="./img/coin.png" className="w-9" />
              <div className="text-3xl font-extrabold">
                {formatNumber(user?.balance || 0)}
              </div>
            </div>
            <div
              className="h-[calc(100vh/2.7)]"
              style={{
                aspectRatio: "1",
              }}
            >
              <img
                ref={imgRef}
                src={user?.league.avatarUrl}
                //@ts-ignore
                onTouchStart={handleTouchStart}
                //@ts-ignore
                onTouchMove={handleTouchMove}
                //@ts-ignore
                onTouchEnd={handleTouchEnd}
                //@ts-ignore
                onTouchCancel={handleTouchEnd}
                onContextMenu={handleContextMenu}
                className={`text-lg border-none filter drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2)) px-4
                ${
                  imageClicked
                    ? "transform scale-90 transition-transform duration-75 ease-in-out"
                    : ""
                }`}
                alt="egg"
              />
            </div>
            <div className="flex w-full justify-between px-4 items-center">
              <div className="flex items-center gap-2">
                <img src="./img/energy.png" className="w-5" />
                <div className="flex text-xl font-extrabold gap-1">
                  <div className="text-[#FE8314]">{user?.energy}</div>
                  <div className="text-[#F8AC2B]">/</div>
                  <div className="text-[#F2E96C]">{user?.maxEnergy}</div>
                </div>
              </div>
              <div>
                <BoostButton />
              </div>
            </div>
          </div>
          {numberSignPositions.map((position) => (
            <NumberSign
              key={position.id}
              x={position.x}
              y={position.y}
              id={position.id}
              rewardPerClick={user?.clickPower || 0}
              onAnimationEnd={handleAnimationEnd}
            />
          ))}
        </>
      )}
    </div>
  );
};

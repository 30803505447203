import React, { useState, useEffect } from "react";
import { useUser } from "../../hooks/useUser";
import { useWebSocket } from "../../hooks/useWebsocket";
import { League, User } from "../../models";
import { formatNumber } from "../../utils/formatNumber";
import { TopControl } from "../../components/TopControl";

export const LeagueTop = () => {
  const { user } = useUser();
  const { webSocket } = useWebSocket();
  const [league, setLeague] = useState<League | null>(null);
  const [usersInLeague, setUsersInLeague] = useState(0);
  const [topUsersInLeague, setTopUsersInLeague] = useState<User[]>([]);

  useEffect(() => {
    if (webSocket && user?.league.id) {
      webSocket.emit("getLeagueInfo", {
        leagueId: user?.league.id,
        topUsersCount: 10,
      });

      webSocket.on("league", (data) => {
        setLeague(data.league);
        setUsersInLeague(data.usersInLeague);
        setTopUsersInLeague(data.topUsersInLeague);
      });

      return () => {
        webSocket.off("league");
      };
    }
  }, [webSocket, user?.league.id]);

  return (
    <div className="h-full flex flex-col">
      <TopControl name="Profile Level" />
      <div className="pt-0 px-4 rounded-lg overflow-scroll">
        <div className="px-16">
          <img src={user?.league.avatarUrl} />
        </div>
        <div className="flex flex-col items-center mt-4">
          <div className="uppercase text-xl">{user?.league.name}</div>
          <div className="flex gap-1 text-[#515A70]">
            <div>{formatNumber(user?.score || 0)}</div>
            <div>/</div>
            <div>{formatNumber(user?.league.maxScore || 0)}</div>
          </div>
        </div>
        <div className="flex flex-col justify-center  items-center rounded-xl mt-8 mb-8 p-4">
          <div className="text-center mb-2">Top Users in League</div>
          <ul className="list-none w-full relative">
            {topUsersInLeague.length > 0 ? (
              topUsersInLeague.map((userL: User, index: number) => (
                <li
                  key={userL.tgId}
                  className="flex justify-between items-center bg-[#333A48B2] rounded-3xl p-4 my-1 shadow-sm"
                >
                  <div className="flex flex-col justify-start">
                    <div>{userL.firstName || "Anonimus"}</div>
                    <div> {formatNumber(userL.score)}</div>
                  </div>
                  <div className="flex text-2xl font-bold justify-center items-center bg-[#0F74D6] rounded-full w-10 h-10">
                    {index + 1}
                  </div>
                </li>
              ))
            ) : (
              <div className="text-center text-xs mt-2">No users in league</div>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

import { useContext, useEffect, useMemo, useState } from "react";
import { DataContext } from "../contexts/DataContext";
import { NotifyContext } from "../contexts/NotifyContext";
import { useUser } from "../hooks/useUser";
import { useWebSocket } from "../hooks/useWebsocket";

export const DailyTask = () => {
  const context = useContext(DataContext);
  const { webSocket } = useWebSocket();
  const notifyContext = useContext(NotifyContext);
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  const dailyDisabled = useMemo(() => {
    if (user) {
      return Date.now() - user.lastDailyRewardTimestamp < 1000 * 60 * 60 * 24;
    } else {
      return true;
    }
  }, [user]);

  useEffect(() => {
    if (webSocket) {
      webSocket.on("boostActivated", (msg) => {
        notifyContext?.setNotify({
          status: "ok",
          message: msg.message,
          className: "h-48",
        });
        setIsLoading(false);
      });
    }

    return () => {
      webSocket?.off("boostActivated");
    };
  }, [webSocket]);

  const onDailyActivate = () => {
    if (webSocket && !dailyDisabled) {
      setIsLoading(true);
      webSocket.emit(
        "activateBoost",
        JSON.stringify([user?.tgId, "dailyReward"])
      );
    } else {
      notifyContext?.setNotify({
        status: "error",
        message: "You already used this today",
        className: "h-48",
      });
    }
  };
  return (
    <li
      className="p-1 pr-4 my-2 bg-[#333A48B2] rounded-2xl flex justify-between items-center shadow-sm"
      onClick={onDailyActivate}
    >
      <div className="w-full flex flex-row justify-left items-center">
        <img
          src="./img/calendar.png"
          className="mr-2 rounded-full w-16 h-16 p-3 object-scale-down"
        />
        <div>
          <span
            className={
              dailyDisabled
                ? "text-xl font-light text-gray-500 leading-none"
                : "text-xl font-light text-white leading-none"
            }
          >
            Daily task
          </span>
          <span
            className={
              "flex felx-row justify-left ml-2 items-center " +
              (dailyDisabled ? "opacity-50" : "")
            }
          >
            <img src="./img/coin.png" className="w-5 h-5" />
            <div className="ml-1 text-lg text-[#E0AE10] font-bold">
              +{context?.settings?.dailyReward}
            </div>
            <div className="text-lg text-[#677496] font-bold ml-1">Billies</div>
          </span>
        </div>
      </div>
      {isLoading && <div>Wait...</div>}
    </li>
  );
};

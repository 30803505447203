import { useNavigate } from "react-router-dom";
import { useUser } from "../hooks/useUser";
import { formatNumber } from "../utils/formatNumber";

export const League = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  if (!user) {
    return <div />;
  }

  return (
    <div
      onClick={() => {
        navigate("/league");
      }}
      style={{ display: "flex", alignItems: "center" }}
      className="w-full justify-between"
    >
      <div
        style={{
          height: "10px",
          backgroundColor: "#131313",
        }}
        className="rounded-xl w-8/12"
      >
        <div
          style={{
            height: "100%",
            width: (user?.score / user?.league.maxScore) * 100 + "%",
            maxWidth:'100%',
            backgroundColor: "#288CEE",
          }}
          className="rounded-xl"
        />
      </div>
      <div className="text-[#288CEE]">{user.league.name}</div>
    </div>
  );
};
